import React from "react";
import styled from "styled-components";

const StyledRatioBox = styled.div`
  position: relative;
  width: 100%;
  color: currentColor;

  ${props => props.image && `
    background-image: url(${props.image});
    background-size: cover;
    background-position: 50% 50%;
  `}
`;

const StyledRatioBoxPadding = styled.div`
  position: relative;
  z-index: 100;
  padding-top: calc((${props => props.h} / ${props => props.w}) * 100%);
`;

const StyledRatioBoxInner = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  h1, h2 {
    margin-bottom: 0;
  }

  ${props => props.flex && `
    display: flex;
    align-items: center;
    justify-content: center;
  `}
  ${props => props.bgColor && `background-color: ${props.bgColor};`}
  ${props => props.color && `
    color: ${props.color}; 
    * {
      color: ${props.color}; 
    }
  `}
`;

class RatioBox extends React.Component {
  render() {
    const r = this.props.ratio.split("/");

    return (
      <StyledRatioBox image={this.props.image}>
        <StyledRatioBoxPadding ratio={this.props.ratio} w={r[0]} h={r[1]} />
        <StyledRatioBoxInner flex={this.props.flex} bgColor={this.props.bgColor} color={this.props.color}>
          {this.props.children}
        </StyledRatioBoxInner>
      </StyledRatioBox>
    );
  }
}

export default RatioBox;
