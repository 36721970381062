import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

import { ContentButtonCss } from "../content";

const MonoStyle = `
  font-family: var(--font-family-mono);
  font-size: 18px;
  letter-spacing: 0.03em;
  
  ${breakpoint("lg")`
      font-size: 21px;
  `}
`;

const MonoLinkStyle = `
  a {
    display: inline-block;
    color: currentColor;
    border-bottom: 2px solid currentColor;
    margin: 0 0 0 0 !important;

    &:hover {
      color: currentColor;
      border-bottom-color: currentColor;
    }
  }
`;

const Mono = styled.div`
  ${MonoStyle}
  ${MonoLinkStyle}
`;

export const MonoBold = styled.div`
  ${MonoStyle}
  font-weight: bold;
`;

export const MonoUppercase = styled.div`
  ${MonoStyle}
  text-transform: uppercase;
`;


export const MonoHeadingStyle = `
  font-family: var(--font-family-mono);
  letter-spacing: 0.06em;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 1.25em;
  margin: 0;
  ${MonoLinkStyle}
`;

export const MonoHeading = styled.h1`
  ${MonoHeadingStyle}
  
  ${breakpoint("md")`
    font-size: 38px;
  `}

  ${breakpoint("lg")`
    font-size: 48px;
  `}
`;

export const MonoHeading2 = styled.h2`
  ${MonoHeadingStyle}

  ${breakpoint("lg")`
    font-size: 38px;
  `}

  ${props => props.wrap && `
    display: inline-block;
    width: 0 !important;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    div {
      display: inline-block;
      transform: translateX(-50%);
    }
  `}
`;

export const MonoHeading3 = styled.h3`
  ${MonoHeadingStyle}

  font-size: 30px;

  ${breakpoint("lg")`
    font-size: 36px;
  `}
`;

export const MonoHeading4 = styled.h4`
  font-size: inherit;
  font-weight: normal;
  color: inherit;
  margin: 0;
`;

export default Mono;
